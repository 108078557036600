import React from "react"
import PageTypes from "../../../components/page-types/_PageTypes"
import Blocks from "../../../components//blocks/_Blocks"
import SbEditable from "storyblok-react"

const detectStoryblokNodeLanguage = (
  story,
  storyblokFolderWhitelist = process.env.GATSBY_STORYBLOK_FOLDER_WHITELIST?.split(
    ","
  ) || undefined,
  useFolderAsLanguage = process.env.GATSBY_USE_FOLDER_AS_LANGUAGE === "true" ||
    false
) => {
  let fallbackLang = "es"
  let originalPath = story.full_slug
  let nodePath = originalPath

  if (originalPath[0] === "/") {
    nodePath = originalPath.substring(1)
  }

  let nodePathFolder = nodePath.split("/")[0]

  if (
    useFolderAsLanguage &&
    storyblokFolderWhitelist.indexOf(nodePathFolder) > -1
  ) {
    return nodePathFolder
  } else {
    return fallbackLang
  }
}

const loadStoryblokBridge = function (cb) {
  let script = document.createElement("script")
  script.type = "text/javascript"
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}`
  script.onload = cb
  document.getElementsByTagName("head")[0].appendChild(script)
}

const contentBlocks = (content) => (
  <div>
    {content.body &&
      content.body.map((block) =>
        React.createElement(Blocks(block.component), {
          key: block._uid,
          block: block,
          type: block.component,
        })
      )}
  </div>
)

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = { story: null, globalNavigation: { content: {} } }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory() {
    // console.log("Load Story")
    window.storyblok.get(
      {
        slug: window.storyblok.getParam("path"),
        version: "draft",
        // resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ story: data.story })
      }
    )
  }

  initStoryblokEvents() {
    this.loadStory()

    let sb = window.storyblok

    sb.on(["change", "published"], (payload) => {
      this.loadStory()
    })

    sb.on("input", (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return <div></div>
    }

    let content = this.state.story.content
    let language = detectStoryblokNodeLanguage(this.state.story)
    let breadcrumbs = [{ link: window.location.pathname, link_text: "Preview" }]
    let subNavItems = [
      { link: window.location.pathname, link_text: "Preview" },
      { link: window.location.pathname, link_text: "Preview" },
      { link: window.location.pathname, link_text: "Preview" },
    ]
    let parentContent = {
      image: { filename: "/placeholder.jpg" },
      headline: "Placeholder Headline",
    }

    return (
      <SbEditable content={content}>
        <div>
          {React.createElement(
            PageTypes(content.component),
            {
              key: content._uid,
              content: content,
              type: content.component,
              subNavItems: subNavItems,
              breadcrumbs: breadcrumbs,
              parentContent: parentContent,
              language: language,
              // navigation: navigation,
            },
            contentBlocks(content)
          )}
        </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
